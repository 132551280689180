.Shell-children {
  min-width: 1080px;
}

.main-content,
.scrollable-sidebar {
  overflow-y: auto;
  -ms-overflow-style: none; 
}

.main-content,
.scrollable-sidebar {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.main-content::-webkit-scrollbar,
.scrollable-sidebar::-webkit-scrollbar {
  display: none; 
}

